<template>
  <div class="task_content">
    <div class="task_content-top">
      <div v-if="tab==='register'">
        <div class="company_authentication">
          <div class="company_font">
            <p>企业暂未认证，认证后可以进行账号充值和发布任务</p>
          </div>
          <div class="company-ren">
            <el-button
              type="primary"
              size="mini"
              @click="RenClick">
              企业认证
            </el-button>
          </div>
        </div>
      </div>
      <div class="task-content-left" v-else-if="tab==='recharge'">
        <div class="task-content-logo">
          <img src="@/assets/images/person-money.png" alt="">
        </div>
          <div class="person-detail">
            <div class="person-title">
              <span>企业账号余额</span>
            </div>
            <div class="person_withdrawal">
              <span class="font-style-two">{{userInfo.amount}}</span>
              <span class="font_style_three">元</span>
            </div>
            <div class="person_withdrawal">
              <el-button
                type="primary"
                size="small"
                style="width: 80px;"
                @click="changeForm(true)">
                充值
              </el-button>
            </div>
          </div>
      </div>
    </div>
    <div class="task-table">
      <el-tabs
        v-model="activeName">
        <el-tab-pane
          label="消费记录"
          name="first">
          <records-consumption></records-consumption>
        </el-tab-pane>
        <el-tab-pane
          label="充值记录"
          name="second">
          <recharge-record></recharge-record>
        </el-tab-pane>
      </el-tabs>
    </div>
    <recharge
      :visible.sync="dialogFormVisible">
    </recharge>
  </div>
</template>

<script>

import Recharge from './components/recharge-dialog.vue'
import RechargeRecord from './components/recharge-record-table.vue'
import RecordsConsumption from './components/records-consumption-table.vue'
export default {
  components: {
    Recharge,
    RechargeRecord,
    RecordsConsumption
  },
  data () {
    return {
      activeName: 'first',
      tab: 'register',
      dialogFormVisible: false
    }
  },
  computed: {
    userInfo () {
      return this.$store.state.userInfo
    }
  },
  created () {
    this.displayClick()
  },
  methods: {
    changeForm (val) {
      this.dialogFormVisible = val
    },
    RenClick () {
      this.$router.push('/company/company-detail')
    },
    displayClick () {
      if (this.userInfo.status === 2 || this.userInfo.status === 3) {
        this.tab = 'recharge'
      } else {
        this.tab = 'register'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.task_content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .task_content-top {
    width: 100%;
    height: 240px;
    background: #FFFFFF;
    background-image: url('../../../..//assets/images/task-right-logo.png');
    background-repeat: no-repeat;
    background-position: right bottom;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    display: flex;
    justify-content:space-between;
    padding:13px 20px 12px 40px;
    .company_font {
      width: 336px;
      height: 20px;
      margin-top: 50px;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #303133;
    }
    .company-ren {
      padding-top: 30px;
    }
    .task-content-left {
      display: flex;
      justify-content: flex-start;
      .font-style-two {
          width: 80px;
          height: 42px;
          font-size: 30px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #5DD5C8;
        }
        .font_style_three {
          width: 14px;
          height: 20px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #5DD5C8;
          margin-left: 5px;
        }
        .person-title {
          width: 240px;
          height: 28px;
          font-size: 20px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #303133;
          margin-top:33px;
        }
        .person_withdrawal {
          margin-top:20px;
        }
      }
    .task-content-logo {
      padding-right: 30px;
    }
    .task-detail-margin {
      margin-left:30px;
    }
  }
  .task-table {
    width: 100%;
    flex: 1;
    background: #FFFFFF;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    margin-top: 20px;
    padding:20px 30px 0px 30px;
  }
}
</style>
