<template>
  <div class="task-history-table">
    <el-table
      :data="data">
      <el-table-column
        label="ID"
        prop="id"
        :index="indexMethod"
        type="index">
      </el-table-column>
      <el-table-column
        label="申请时间"
        prop="created_at">
      </el-table-column>
      <el-table-column
        label="任务金额"
        prop="amount">
      </el-table-column>
      <el-table-column
        label="任务接受人"
        prop="username">
      </el-table-column>
      <el-table-column
        label="联系方式"
        prop="mobile">
      </el-table-column>
      <el-table-column
        label="任务名称"
        prop="title">
      </el-table-column>
    </el-table>
    <div class="task-pages">
      <pagination
        @paginationChange="paginationChange"
        :pageInfo="pageInfo">
      </pagination>
    </div>
  </div>
</template>
<script>
import api from '@/api'
import Pagination from '@/components/pagination/index.vue'
export default {
  components: {
    Pagination
  },
  data () {
    return {
      pageInfo: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      data: []
    }
  },
  created () {
    this.getConsumptionList()
  },
  methods: {
    paginationChange (val) {
      this.pageInfo.pageNum = val
      this.getConsumptionList()
    },
    getConsumptionList () {
      this.isLoading = true
      api.consumption({
        ...this.pageInfo
      }).then(res => {
        this.isLoading = false
        if (res.data.code === 0) {
          const { total, list, currentPage } = res.data.data
          this.pageInfo.total = total
          this.data = list
          this.pageInfo.pageNum = currentPage
        } else {
          this.$message.error('获取列表失败')
        }
      }).catch((err) => {
        console.log(err)
        this.$message.error('请求错误')
      })
    },
    indexMethod (index) {
      // 当前页数 - 1 * 每页数据条数 + index + 1
      return (this.pageInfo.pageNum - 1) * this.pageInfo.pageSize + index + 1
    }
  }
}
</script>

<style lang="scss" scoped>
.task-pages {
    margin-top: 20px;
    text-align: center;
  }
</style>
