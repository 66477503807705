<template>
  <el-dialog title="充值"
    :visible.sync="$_visible"
    width="500px"
    :show-close="false"
    :close-on-click-modal="false"
    class="login_dialog">
    <el-form
      :model="form"
      ref="form"
      :rules="rules">
      <el-form-item
        label=""
        :required="true"
        prop="amount">
        <el-input
          type="text"
          placeholder="请输入充值金额"
          v-model="form.amount"
          size="small"
          style="width:420px;">
        </el-input>
        <span class="tip">请给银行卡号{{cardNo}}转账，并备注机构名称</span>
      </el-form-item>
      <el-form-item
        label="打款凭证"
        prop="attachments">
        <el-upload
          class="avatar-uploader"
          :action="uploadUrl"
          :show-file-list="false"
          ref="uploadFiles"
          :headers="header"
          :accept="accept"
          :on-success="fileSuccess"
          :before-upload="beforeAvatarUpload">
          <img v-if="form.attachments" :src="form.attachments.url" class="avatar">
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button
        @click="closeDialog"
        size="small">
        取消
      </el-button>
      <el-button
        @click="rechargeMoney"
        type="primary"
        :loading="btnLoading"
        size="small">
        确定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import api from '@/api'
import globalVar from '@/configs/globalVar'
import cookie from '@/plugins/cookie'
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      form: {
        amount: '',
        attachments: null
      },
      rechargeVoucher: {},
      btnLoading: false,
      rules: {
        amount: [
          { required: true, message: '请输入金额', trigger: 'blur' },
          { pattern: /^(?!0+(?:\.0+)?$)(?:[1-9]\d*|0)(?:\.\d{1,})?$/, message: '请输入充值金额', trigger: 'blur' }
        ],
        attachments: [
          { required: true, message: '请上传打款凭证', trigger: 'blur' }
        ]
      },
      accept: 'image/jpeg,image/png'
    }
  },
  computed: {
    $_visible: {
      get () {
        return this.visible
      },
      set (newValue) {
        this.$emit('update:visible', newValue)
      }
    },
    cardNo () {
      return globalVar.CARDNO
    },
    header () {
      const token = cookie.get(globalVar.TOKEN)
      return {
        Authorization: 'Bearer ' + token
      }
    },
    uploadUrl () {
      return process.env.VUE_APP_API_BASE_URL + 'api/v1/media/put'
    }
  },
  methods: {
    closeDialog () {
      this.$_visible = false
      this.form.attachments = {}
      this.$refs.form.resetFields()
    },
    // 文件上传之前
    beforeAvatarUpload (file) {
      if (this.accept && this.$refs.uploadFiles.accept.search(file.type) === -1) {
        this.$message.warning('上传失败，文件格式不正确！')
        return false
      }
    },
    // 上传成功
    fileSuccess (res, file) {
      console.log('上传成功', res, file)
      const obj = {
        uid: file.uid,
        name: file.name,
        ...res
      }
      this.form.attachments = obj
    },
    // 充值
    rechargeMoney () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.btnLoading = true
          api.recharge({
            ...this.form
          }).then(res => {
            this.btnLoading = false
            if (res.data.code === 0) {
              this.$message.success('充值已提交，等待审核')
              this.closeDialog()
            } else {
              this.$message.error('充值失败')
            }
          }).catch((error) => {
            this.btnLoading = false
            console.log(error)
            this.$message.error('请求出错')
          })
        }
      })
    }
  }
}
</script>

<style lang="scss">
.tip{
  font-size: 14px;
  font-weight: 500;
  line-height: 0px;
  color: #909399;
}
.login_dialog {
  .el-dialog {
    .el-dialog__header {
      padding: 19px 0px 0px 20px;
    }
    .el-dialog__body {
      padding: 19px 40px 30px 40px;

    }
    .el-dialog__footer {
      padding: 0px 20px 20px 0px;
    }
  }
    .avatar-uploader .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
      border-color: #409EFF;
    }
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 178px;
      height: 178px;
      line-height: 178px;
      text-align: center;
    }
    .avatar {
      width: 178px;
      height: 178px;
      display: block;
    }
  }
</style>
